@use "../colors" as *;

.space-between {
    justify-content: space-between;
    align-items: flex-start;
}

.spacer {
    height: 600px;
}

.nav-button {
    margin-top: -30px;
    margin-left: -50px;
    margin-right: 25px;
}

.nav-login-button {
    border: 2px solid $primary-base-color;
    border-radius: 25px;
    padding-inline: 20px;
    color: $primary-base-color;
}

.nav-login-button:hover {
    color: $gray-1-color;
    border-color: $primary-base-color;
    background-color: $primary-base-color;
}

.button-container {
    margin-top: 40px;
    width: 10rem;
    margin-right: 8em;
}

.cover {
    background-color: black;
    height: 500px;
    width: 100%;
    z-index: 10;
    opacity: .45;
}

.absolute {
    position: absolute;
}

.main-container {
    max-height: 500px;
}

.img-container {
    max-height: 500px;
    overflow: hidden;
    z-index: 1;
}

.red {
    background-color: $red-base-color;
}

.blue {
    background-color: $blue-base-color;
}

.grey {
    background-color: $gray-base-color;
}

.mt-500 {
    margin-top: 500px;
}

.contact-form {
    display: flex;
    justify-content: center;
}

.contact-button{
    border: 2px solid white;
    border-radius: 25px;
    padding-inline: 20px;
    color: $primary-base-color;
}

.video-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.video-container video {
    width: 60%;
    margin: 35px;
}

.email-button{
    margin-bottom: 50px;
    background-color: $blue-base-color;
    padding-inline: 25px;
    padding-block: 5px;
    border: 2px solid $blue-base-color;
    border-radius: 20px;
    color: white;
}

.email-button:hover{
    color: $blue-base-color;
    background-color: white;
}

@media (max-width: 768px) {
    .button-container {
        font-size: 12px;
        width: 5.5rem;
        margin-right: 30px;
    }

    .contact-button{
        visibility: hidden;
    }

    .video-container video {
        width: 90%;
    }

    .max-w-sm {
        width: 200px;
    }

    .mr-12 {
        margin-right: -25px !important;
    }

    .text-6xl {
        font-size: 30px !important;
    }

    .mini-width {
        width: 75% !important;
    }

    .login-button {
        margin-top: -30px;
    }

    .main-container,
    .cover,
    .img-container {
        height: 300px;
    }

    .mt-500 {
        margin-top: 0px;
    }

    .mt-64 {
        margin-top: 125px !important;
    }

    .spacer {
        height: 50px;
    }
}

