@use "typography" as *;
@use "colors" as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}
*::placeholder {
  color: inherit;
  opacity: $placeholder-opacity;
}
:root {
  font-family: $font-family;
  font-size: 16px;
  height: 100%;
  @media only screen and (max-width: 1900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 2000px) {
    font-size: 18px;
  }
}
#root {
  height: 100%;
}
.App {
  height: 100%;
}
body {
  font-size: $main-text-size;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.material-icons,
.material-icons-outlined {
  font-size: 1.3em;
  vertical-align: text-bottom;
}
.sideways-right {
  transform: rotate(90deg);
}
.sideways-left {
  transform: rotate(-90deg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bounce-loader{
  position: absolute;
  margin: 0 auto;
}

.no-flip{
  transform: rotate(0deg);
}

.flip{
  transform: rotate(180deg);
}

.role-sort:hover{
  background-color: $gray-1-color;
}

.hide{
  visibility: hidden;
  position: absolute;
}
