@use "../colors" as *;

.attendance-grid {
  grid-template-columns: repeat(6, auto) 1fr;
}

.attendance-grid thead tr > * {
  //   background-color: $gray-base-color;
}

.attendance-grid tbody tr:nth-child(2n + 1) > * {
  background-color: $gray-1-color;
}

@media (max-width: 768px) {
  .attendance-grid {
    display: block;
    overflow-x: scroll;

    .attendance-grid__notes {
      min-width: 300px;
    }
  }
}
