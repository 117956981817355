.centered-modal {
  position: fixed;
  top: 5vh;
  left: 10%;
  width: 80%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  overflow-y: scroll;
}

.large-modal {
  max-height: 95%;
}

.small-modal {
  top: 20vh;
  max-height: 40rem;
}

.centered-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 30;
}
.dark-bg {
  background-color: rgba(0, 0, 0, 0.75);
}
.light-bg {
  background-color: none;
}

@media (min-width: 768px) {
  .large-modal {
    // width: 60rem;
    // left: calc(50% - 30rem);
  }
  .small-modal {
    width: 60rem;
    left: calc(50% - 10rem);
  }
}
// @media (min-width: 1400px) {
//   .large-modal {
//     top: 10vh;
//   }
// }

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
