@use "../colors" as *;

.rbc-calendar {
  // height: 100vh;
  // width: 80vw;
  overflow-y: visible;

  $border: 1px solid rgb(182, 180, 180);
  color: #525252;

  .rbc-toolbar {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-bottom: 1rem;

    .rbc-btn-group {
      button {
        box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
        cursor: pointer;
        font-weight: 500;
        background-color: white;
        padding: 0.5rem 1rem;
        // border: solid 1px rgb(182, 180, 180);
        // &:first-child {
        //   border-top-left-radius: 8px;
        //   border-bottom-left-radius: 8px;
        //   border-right: none;
        // }

        // &:last-child {
        //   border-top-right-radius: 8px;
        //   border-bottom-right-radius: 8px;
        //   border-left: none;
        // }

        &:hover {
          background-color: rgb(245, 245, 245);
        }
        @media (max-width: 768px) {
          // &:first-child {
          //   width: 90%;
          //   border-radius: 8px;
          //   border-right: 1px solid rgb(182, 180, 180);
          //   margin-bottom: 0.5rem;
          // }

          // &:nth-child(2) {
          //   border-top-left-radius: 8px;
          //   border-bottom-left-radius: 8px;
          // }

          // &:last-child {
          //   border-top-right-radius: 8px;
          //   border-bottom-right-radius: 8px;
          // }
        }
      }
    }
    .rbc-btn-group ~ .rbc-btn-group * {
      display: none;
    }

    .rbc-toolbar-label {
      align-self: center;
      font-weight: 600;
    }
  }

  .rbc-month-view {
    // overflow: hidden;
    display: grid;
    grid-template-rows: auto;
    grid-auto-rows: 1fr;

    .rbc-month-header {
      margin-bottom: 1rem;
      .rbc-header {
        text-align: right;
        margin-right: 1rem;
        text-transform: uppercase;
      }
    }

    .rbc-row {
      display: flex;
      // justify-content: stretch;

      .rbc-row-segment {
        // max-width: unset;

        // + .rbc-row-segment {
        //   border-left: $border;
        // }

        .rbc-show-more {
          margin-left: 1rem;
          color: $calendar-gray;

          &:hover {
            color: #273952;
          }
        }
      }

      .rbc-header,
      .rbc-date-cell {
        flex-basis: 14.2857%;
        max-width: 14.2857%;
        // padding: 0 1rem;
        margin: 0 0.25rem;
        // z-index: -1;
        text-align: center;
        color: $calendar-gray;
        font-weight: 400;
        // + .rbc-header {
        //   border-left: $border;
        // }
      }

      .rbc-date-cell {
        border-top: 1px solid $calendar-lightgray;
        // border-top: 2px solid black;
        padding: 0 0.25rem;
      }

      .rbc-button-link {
        border: none;
        background: none;
      }

      .event-wrapper {
        width: 95%;
        text-align: left;
        border: none;
        // background: $blue-base-color;
        padding: 0.25em;
        color: $calendar-gray;
        font-weight: 400;
        line-height: 19.5px;

        &:hover {
          color: #273952;
        }
      }

      .active-event {
        background: $success-color;
      }
    }

    .rbc-month-row {
      position: relative;
      min-height: 10rem;

      .rbc-row-content {
        z-index: unset;
      }

      .rbc-row-bg {
        display: flex;
        position: absolute;

        inset: 0;
        height: 100%;
        z-index: -1;

        .rbc-day-bg {
          flex-basis: 14.2857%;
          max-width: 14.2857%;
          text-align: center;
          margin: 0 0.25rem;
          height: 100%;
          width: 100%;
        }
      }

      // some extra padding above the date
      .rbc-row .rbc-button-link {
        padding-top: 0.5rem;
      }
      &:nth-child(n + 3) {
        grid-template-columns: repeat(7, 1fr);
        // border-top: $border;
      }
    }

    .rbc-off-range {
      button {
        color: $gray-base-color;
      }
    }
    .rbc-off-range-bg {
      // background: $gray-2-color;
    }

    .rbc-today {
      background-color: #3e6db114;
    }
  }
}
.restricted {
  .rbc-day-bg {
    color: $gray-base-color;
    background: $gray-2-color;
  }
}

.select-arrow {
  background: url("../../../public/img/down-arrow.svg") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important; 
    appearance: none !important;
    padding-right: 2rem !important;
}
