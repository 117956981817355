@use "../colors" as *;

.loader {
  position: fixed;
  top: 40vh;
  left: 50%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 27;
  background-color: rgba(0, 0, 0, 0.15);
}

.dashboard-content {
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem 6rem;
  // overflow: scroll;
  // margin-left: 19rem;

  .hamburger-icon {
    color: $gray-base-color;
    width: 3.5rem;
    border: solid 1px $gray-1-color;
    border-radius: 8px;
    padding: 0.25rem 0.45rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
    margin-bottom: 2rem;
    display: none;
  }
  .show-more__modal {
    overflow-y: scroll;
    height: fit-content;
    min-height: 30rem;
    min-width: 20rem;
    bottom: 15rem;
  }

  .add-intervention__btn {
    margin-top: 2rem;
  }
}

.header {
  flex: 0 0 auto;
}

.page {
  flex: 1 1 auto;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 0.75rem;
    height: 100%;
    background: #fff;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #f6f6f6;
  }
  
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #c3bfbf;

    &:hover {
      background-color: hsl(212, 57%, 53%);
    }
  }
}

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 1rem;
    margin-right: 1rem;
    .hamburger-icon {
      display: block;
    }

    .show-more__modal {
      left: 5rem;
    }
  }

  .loader {
    left: calc(50% - 5rem);
  }
}