@import url("https://fonts.googleapis.com/css2?family=Material+Icons&family=Material+Icons+Outlined&family=Space+Grotesk:wght@400;500;700&display=swap");

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SF-Pro-Text-Semibold.otf')format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SFPRODISPLAYMEDIUM.OTF')format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SFPRODISPLAYREGULAR.OTF')format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SF-Pro-Display-Light.otf')format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SFPRODISPLAYBOLD.OTF')format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SFPRODISPLAYLIGHTITALIC.OTF')format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro';
  src: url('../fonts/SFPRODISPLAYHEAVYITALIC.OTF')format('truetype');
  font-weight: 700;
  font-style: italic;
}


$font-family: 'SF Pro', sans-serif;
$login-header-size: 5.5rem; // 88px;
$header-size: 2.625rem; // 42px;
$sub-header-size: 2rem; // 32px
$large-text-size: 1.25rem; // 20px
$main-text-size: 1.125rem; // 18px
$subtext-size: 1rem; // 16px
