@use "../../colors" as *;

.sidebar-layout {
  display: grid;
  height: 100%;
  width: 100vw;
  grid-template-columns: 16.75rem auto; // 268px
  grid-template-areas: "side main";
  .sidebar {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 7px 5px rgba(0, 0, 0, 0.06),
      0 8.3px 00.9px rgba(0, 0, 0, 0.072), 0 10.8px 10.4px rgba(0, 0, 0, 0.086),
      0 0px 0px rgba(0, 0, 0, 0.12);
    height: 100%;
    background-color: $gray-1-color;
    text-align: center;
    padding: 0.375rem 0 0.25rem; // 6px 0 4px
    display: flex;
    position: fixed;
    z-index: 29;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    // width: 22rem;

    .sidebar-top {
      height: auto;
      h1 {
        height: 2.5rem; //40px
        color: white;
        font-size: 2rem; //32px
        margin: 3rem auto 0; //48px auto 0
      }
      .profile-photo {
        margin: 1.5rem auto; // 24px auto
      }
      .button-component,
      .navbar-link {
        min-height: 0;
      }

      .navbar-logo {
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 15rem;
      }

      .navbar-user-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .navbar-username {
          font-weight: 500;
          color: $text-color;
        }

        span {
          color: rgb(105, 105, 105);
          font-size: 1rem;
        }
      }
    }
    .sidebar-bottom {
      height: auto;
    }

    .logout-button,
    .settings-button {
      background-color: $gray-1-color;
      color: hsl(212, 57%, 53%);
      &:hover {
        background-color: hsl(192, 3%, 83%);
      }
    }
    .button-component,
    .navbar-link {
      margin: 0.75rem 1.5rem; //12px 24px
      width: 13.75rem; //220px
      min-width: 0;
    }

    .nav-links_container {
      margin-top: 3rem;
    }
  }
  .sidebar-main-content {
    height: auto;
    width: 100%;
  }

  @media (max-width: 768px) {
    .sidebar-main-content {
      width: 100%;
    }
  }
}

.navbar {
  @media screen and (max-width: 768px) {
    // animation: slide-from-left 400ms ease-out forwards;
    &:not(.open) {
      animation-direction: reverse;
      transform: translateX(-100%);
      display: none;
    }

    .nav-logo {
      display: none;
    }
  }
}

@keyframes slide-from-left {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
