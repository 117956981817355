//Text colors
$placeholder-color: #999999;
$text-color: #333333;
$placeholder-opacity: 0.5;
$subtext-color: #908888;

//Main colors;
$blue-base-color: hsl(212, 57%, 53%);

$gray-base-color: hsl(192, 3%, 63%);
$gray-1-color: hsl(192, 3%, 93%);
$gray-2-color: hsl(192, 3%, 95%);

$red-base-color: hsl(360, 57%, 48%);
// Old colors for compatibility, replace as we can
$primary-base-color: hsl(212, 57%, 53%);
$primary-1-color: hsl(212, 57%, 43%);
$blue-lighter: hsl(212, 57%, 63%);
$blue-lightest: hsl(212, 57%, 73%);
$success-color: hsl(125, 90%, 38%);
$error-color: hsl(0, 73%, 54%);
$error-light-color: hsl(0, 73%, 84%);
$red-functional: hsl(0, 73%, 64%);


// Calendar
$calendar-gray: #778190;
$calendar-lightgray: #EBEEF4;