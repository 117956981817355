.modal {
    position: absolute;
    // top: 50px;
    margin-top: .2rem;
    // margin-left:5rem ;
    // left: ;
    width: max-content;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    z-index: 30;
    animation: slide-down 300ms ease-out forwards;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    background-color: transparent;
}

@media (min-width: 768px) {
    .modal {
    // width: 40rem;
    // left: calc(50% - 20rem);
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}