.embla {
  margin: auto;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
}
.embla__slide {
  flex: 0 0 auto;
  min-width: 0;
  padding: 1rem; /* Add padding to ensure consistent spacing */
}