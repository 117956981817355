@use "../colors" as *;

.attendees-searchbar {
  input {
    padding: .25rem .5rem;
    width: 100%;
    border: solid 1px #ddd;
    border-radius: .375rem;
    
    &:focus {
      // border-color: red;
      border-radius: 4px;
      outline: $blue-base-color 2px solid;
    }
  }
}