@use "../../colors" as *;

.users_list__container {
  max-height: 40rem;
}

.user-list__grid {
  grid-template-columns: repeat(3, 1fr) auto auto auto;
  max-height: 60vh;

  .user-delete__icon {
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
}
.settings-students-list__grid {
  grid-template-columns: repeat(4, .5fr);
  max-height: 60vh;
}

.settings-students-list__grid tbody tr:nth-child(2n + 1) > * {
  background-color: #f3f3f3;
}

.user-list__grid thead tr > * {
  //   background-color: $gray-base-color;
}

.user-list__grid tbody tr:nth-child(2n + 1) > * {
  background-color: $gray-1-color;
}

.users-list__modal {
  top: 5vh;
  left: 40%;
  width: fit-content;
  max-height: 95%;
}

@media (max-width: 1000px) {
  .users-list__modal {
   left: 20%;
  }
}
