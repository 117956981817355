@use "../typography" as *;
@use "../colors" as *;

.login-main {
  width: 28rem; //510px
  height: 100vh;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 80%;
    }
  }

  .text-input-component {
    .wrapper {
      .text-svg-container {
        opacity: 50%;
        height: 1.5rem;
        margin: 1.1em;
      }
      // width: ;
    }
  }

  .login-button {
    margin: 2em auto 1.5rem;
    margin-top: .5rem;
    background-color: $blue-base-color;
  }

  .forgot-password-button p {
    margin: 0;
  }

  @media (min-width: 768px) {
    .text-input-component {
      .wrapper {
        width: 30rem;
      }
    }
  }
}
