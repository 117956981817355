.add-intervention__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  input {
    margin-left: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 1rem 1.5rem;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
  }

  h2 {
    align-self: center;
    font-weight: 500;
  }

  button {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034);
  }
}
