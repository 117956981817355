// If you are creating a new page that needs styles, create a new file .scss in the /pages folder.
// component styling can be put inside of the /components folder
// .scss files can be imported as such: @use './styles/foo/bar';

@use "root";

@use "components/svg/text-svg-container";
@use "components/svg/eye-svg";
@use "components/svg/home-svg";
@use "components/svg/location-svg";
@use "components/svg/search-svg";
@use "components/svg/logo-svg";
@use "components/svg/email-svg";
@use "components/svg/lock-svg";
@use "components/svg/fire-svg";
@use "./components/svg/logout-svg";
@use "./components/svg/progress-svg";

// components
@use "components/text-input";
@use "components/small-text-input";
@use "components/button";
@use "./components/calendar";
@use "./components/event-popup";
@use "./components/modal";
@use "./components/centered-modal";
@use "./components/layouts/navbar-link";
@use "./components/layouts/sidebar";
@use "./components/add-intervention";
@use "./components/search-bar";
@use "./components/layouts/carousel";

// admin components
@use "./components/admin/users-list";

// pages
@use "./pages/login";
@use "./pages/dashboard";
@use "./pages/attendance";
@use "./pages/reports";
@use "./pages/home";
@use "./pages/_csv_upload";

@tailwind base;
@tailwind components;
@tailwind utilities;
