.reports-page {
  fieldset {
    min-width: 0;
  }

  .report-controls-grid {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 10em));
    gap: 1em 2em;
  }

  .report-controls-grid label {
    min-width: max-content;
  }

  @media print {
    *:not(.dashboard-content, table, table *) {
      display: none;
    }

    .dashboard-content {
      margin: 0;
    }
  }

  .show-checkbox {
    visibility: visible;
  }

  .hide-checkbox {
    visibility: hidden;
    margin-top: -30px;
  }
}
