@use "../colors" as *;
@use "../typography" as *;
$input-background-color: #ffffff;
$input-border-color: #dddddd;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 3.125rem $input-background-color inset !important;
}

.text-input-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.6rem;
  text-align: left;
  font-family: $font-family;
  .input-error-message {
    font-size: 0.875rem;
    color: $error-color;
    margin: 0.5em;
    text-align: left;
    width: 90%;
  }
  .label {
    display: block;
    margin: 1.5em 0 0.5em;
    align-self: start;
    padding-left: 1rem;
    font-weight: 700;
    font-size: $subtext-size;
  }
  .wrapper {
    display: flex;
    flex-flow: row nowrap;
    width: 25rem;
    background-color: $input-background-color;
    border: 2px solid $input-border-color;
    border-radius: 0.9375rem;
    &:focus-within {
      border-color: $primary-base-color;
    }
    .prefix {
      color: $text-color;
      align-items: center;
    }
    .input {
      display: grid;
      flex: 1 1 auto;
      background-color: inherit;
      border: none;
      min-width: 0;
      font-size: $subtext-size;
      color: $text-color;
      font-family: $font-family;
      text-overflow: ellipsis;
      line-height: inherit;
      padding: 0.5rem 1rem;
      border-radius: inherit;
      &:focus {
        outline: none;
      }
    }
    .password-toggle {
      display: grid;
      place-items: center;
      flex: initial;
      background-color: inherit;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      border-radius: inherit;
      &.hidden {
        display: none;
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
        opacity: $placeholder-opacity;
      }
    }
  }
  &.invalid:not(:focus-within) {
    .label,
    .input {
      color: $error-color;
    }
    .wrapper {
      border-color: $error-color;
      background-color: $error-light-color;
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        box-shadow: 0 0 0 3.125rem $error-light-color inset !important;
      }
    }
  }
}
